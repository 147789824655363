<template>
  <v-data-table
    :headers="headers"
    :items="datas"
    mobile-breakpoint="0"
    class="header-stretch table-contact-company"
    :items-per-page="25"
    :hide-default-footer="true"
  >
    <!-- <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("page.profile.contact_info") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 button"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("add") }}
            </v-btn>
          </template>
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{
                $t("page.profile.add_new_contact")
              }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                <v-text-field
                  ref="name"
                  v-model="name"
                  :rules="[() => !!name || $t('is_required')]"
                  :error-messages="errorMessages"
                  :label="$t('page.profile.full_name')"
                  :placeholder="$t('page.profile.full_name')"
                  class="fontSet uppercase-input"
                ></v-text-field>
                <v-autocomplete
                  ref="title"
                  v-model="title"
                  :rules="[() => !!title || $t('is_required')]"
                  :items="titles"
                  :label="$t('field.title')"
                  :placeholder="`${$t('select')}...`"
                  class="fontSet uppercase-input"
                ></v-autocomplete>
                <v-row class="pt-6">
                  <v-col cols="3" class="column-phone-code">
                    <PhoneNumber
                      :items="phoneCodeItems"
                      :value="defaultPhoneCode"
                      class="input-phone"
                      :rules="[
                        (v) =>
                          !!v || `${$t('field.phone')} ${$t('is_required')}`,
                      ]"
                      @changePhoneCode="(selected) => update(selected)"
                    />
                  </v-col>
                  <v-col cols="9" class="column-phone-number">
                    <v-text-field
                      required
                      dense
                      v-model="formatedPhoneNumber"
                      :rules="[
                        (v) =>
                          !!v || `${$t('field.phone')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.phone')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  ref="email"
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  :label="$t('field.email_address')"
                  :placeholder="$t('field.email_address')"
                  class="fontSet uppercase-input"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="close"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{
                $t("page.profile.edit_new_contact")
              }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
                <v-text-field
                  ref="name"
                  v-model="editedItem.name"
                  :rules="[() => !!editedItem.name || $t('is_required')]"
                  :error-messages="errorMessages"
                  :label="$t('page.profile.full_name')"
                  :placeholder="$t('page.profile.full_name')"
                  class="fontSet uppercase-input"
                ></v-text-field>
                <v-autocomplete
                  ref="title"
                  v-model="editedItem.title"
                  :rules="[() => !!editedItem.title || $t('is_required')]"
                  :items="titles"
                  :label="$t('field.title')"
                  :placeholder="`${$t('select')}...`"
                  class="fontSet uppercase-input"
                ></v-autocomplete>
                <v-row>
                  <v-col cols="3" class="column-phone-code">
                    <PhoneNumber
                      :items="phoneCodeItems"
                      :value="defaultPhoneCodeUpdate"
                      class="input-phone"
                      :rules="[
                        (v) =>
                          !!v || `${$t('field.phone')} ${$t('is_required')}`,
                      ]"
                      @changePhoneCode="(selected) => updatePhone(selected)"
                    />
                  </v-col>
                  <v-col cols="9" class="column-phone-number">
                    <v-text-field
                      required
                      dense
                      v-model="formatedPhoneNumberUpdate"
                      :rules="[
                        (v) =>
                          !!v || `${$t('field.phone')} ${$t('is_required')}`,
                      ]"
                      :label="$t('field.phone')"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  ref="email"
                  v-model="editedItem.email"
                  :rules="[rules.required, rules.email]"
                  :label="$t('field.email_address')"
                  :placeholder="$t('field.email_address')"
                  class="fontSet uppercase-input"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="closeEdit"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("confirmation_dialog") }}
            </v-card-title>
            <v-card-text>
              {{ $t("page.profile.u_sure_want_delete") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete">{{ $t("cancel") }} </v-btn>
              <v-btn color="#00a4b3" text @click="deleteItemConfirm"
                >{{ $t("oke") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPrimary" max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("confirmation_dialog") }}
            </v-card-title>
            <v-card-text>
              {{ $t("page.profile.u_sure_make_contact_primary") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closePrimary">{{ $t("cancel") }}</v-btn>
              <v-btn color="#00a4b3" text @click="makePrimaryConfirm">{{
                $t("oke")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template> -->
    <template v-slot:item="{ item }">
      <tr>
        <!-- <td valign="top" class="pt-2">
          <v-icon small @click="editItem(item)" style="color:#00a4b3;"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)" style="color:#00a4b3;"> mdi-delete </v-icon>
          <v-icon
            small
            @click="makePrimary(item)"
            v-if="item.status != 'PRIMARY'"
            style="color:#00a4b3;"
          >
            mdi-heart
          </v-icon>
        </td> -->
        <td valign="top" class="pt-2">{{ item.title }}</td>
        <td valign="top" class="pt-2 tb-wide">{{ item.name }}</td>
        <td valign="top" class="pt-2">{{ item.phone }}</td>
        <td valign="top" class="pt-2">{{ item.email }}</td>
        <td valign="top" class="pt-2">{{ item.position }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
// import PhoneNumber from "@/components/v2/input/phoneNumberNonOutline.vue";

export default {
  components: {  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    dialogPrimary: false,
    formHasError: false,
    headers: [],
    datas: [],

    editedIndex: -1,
    name: null,
    title: null,
    email: null,
    phone: null,
    position: null,
    errorMessages: "",
    editedItem: {
      name: "",
      title: "",
      phone: "",
      email: "",
      position: "",
    },
    defaultItem: {
      name: "",
      title: "",
      phone: "",
      email: "",
      position: "",
    },
    titles: ["Mr", "Mrs", "Ms"],
    rules: {},
    defaultPhoneCode: "+62",
    defaultPhoneCodeUpdate: "+62",
    
  }),
  created() {
    this.rules = {
      required: (value) => !!value || this.$t("is_required"),
      counter: (value) => value.length <= 13 || this.$t("max_13_char"),
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || this.$t("email_invalid");
      },
    };
    this.headers = [
    { text: this.$t("field.title"), value: "title", sortable: false },
      {
        text: this.$t("page.profile.full_name"),
        align: "start",
        sortable: false,
        value: "name",
      },
     
      { text: this.$t("field.mobile_phone"), value: "phone", sortable: false },
      {
        text: this.$t("field.email_address"),
        value: "email",
        sortable: false,
      },
      {
        text: this.$t("field.position_title"),
        value: "position",
        sortable: false,
      },
     
    ];
    this.getDataContact();
  },
  computed: {
    // formatedPhoneNumber: {
    //   get() {
    //     return this.phone;
    //   },
    //   set(param) {
    //     if (param != null && param.length > 4 && param.substring(0, 1) == "0") {
    //       this.phone = param.substring(1);
    //     } else {
    //       this.phone = param;
    //     }
    //   },
    // },
    // formatedPhoneNumberUpdate: {
    //   get() {
    //     const phoneFormat = this.editedItem.phone;

    //     this.phoneCodeItems.map((data) => {
    //       if (data.code == phoneFormat.substring(0, data.code.length)) {
    //         this.editedItem.phone = phoneFormat.substring(data.code.length);
    //         this.defaultPhoneCodeUpdate = data.code;
    //       }
    //       return data;
    //     });
    //     return this.editedItem.phone;
    //   },
    //   set(param) {

    //     if (param != null && param.length > 4 && param.substring(0, 1) == "0") {
         
    //       this.editedItem.phone = param.substring(1);
    //     } else {
    //       this.editedItem.phone = param;
    //     }
    //   },
    // },
    form() {
      return {
        name: this.name,
        title: this.title,
        phone: this.phone,
        email: this.email,
        position: this.position,
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogPrimary(val) {
      val || this.closePrimary();
    },
    name() {
      this.errorMessages = "";
    },
  },

  methods: {
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    editItem(item) {
      // const phoneFormat = item.phone;

      // this.phoneCodeItems.map((data) => {
      //   if (data.code == phoneFormat.substring(0, data.code.length)) {
      //     item.phone = phoneFormat.substring(data.code.length);
      //     this.defaultPhoneCodeUpdate = data.code;
      //   }
      //   return data;
      // });

      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    makePrimary(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPrimary = true;
    },

    deleteItemConfirm() {
      let vm = this;
      try {
        let form = { id: this.datas[this.editedIndex].id };
        (async function () {
          const res = await API.del(
            `${process.env.VUE_APP_API_TRUST}contact/delete`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            vm.toast("success", vm.$t("field.data_delete_success"));
            setTimeout(function () {
              vm.getDataContact();
            }, 200);
          } else if (res.statusCode == 403) {
            vm.toast("error", res.message);
            // Object.keys(res.errors).forEach((element) => {
            //   vm.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            vm.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
        })();
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          text: vm.$t("error_try_again_latter"),
        });
      }
      this.closeDelete();
    },

    makePrimaryConfirm() {
      let vm = this;
      try {
        let form = { id: this.datas[this.editedIndex].id };

        (async function () {
          const res = await API.post(
            `${process.env.VUE_APP_API_TRUST}contact/make-primary`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            vm.toast("success", vm.$t("field.data_edit_success"));
            setTimeout(function () {
              vm.getDataContact();
            }, 200);
          } else if (res.statusCode == 403) {
            vm.toast("error", res.message);
            // Object.keys(res.errors).forEach((element) => {
            //   vm.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            vm.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
        })();
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          text: vm.$t("error_try_again_latter"),
        });
      }
      this.closePrimary();
    },

    close() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePrimary() {
      this.dialogPrimary = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    updatePhone(e) {
      this.defaultPhoneCodeUpdate = e;
    },
    save() {
      if (this.$refs.form.validate() == true) {
        // reference instance ke variabel karena tidak akan terbaca pada proses async
        var vm = this;

        // Jika ada edit item, karena jika diedit index akan selalu lebih dari -1
        if (this.editedIndex > -1) {
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          try {
            let form = {
              id:
                typeof this.editedItem.id !== "undefined"
                  ? this.editedItem.id
                  : 0,
              name:
                typeof this.editedItem.name !== "undefined"
                  ? this.editedItem.name.toString().toUpperCase()
                  : "",
              title:
                typeof this.editedItem.title !== "undefined"
                  ? this.editedItem.title
                  : "",
              phone:
                typeof this.editedItem.phone !== "undefined"
                  ? this.defaultPhoneCodeUpdate +
                    this.editedItem.phone.replace(/\s/g, "").replaceAll("-", "")
                  : "",
              email:
                typeof this.editedItem.email !== "undefined"
                  ? this.editedItem.email.toString().toUpperCase()
                  : "",
            };

            // Begin api request
            (async function () {
              const res = await API.put(
                `${process.env.VUE_APP_API_TRUST}contact/update`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("field.data_edit_success"));
                setTimeout(function () {
                  vm.getDataContact();
                }, 200);
              } else if (res.statusCode == 403) {
                vm.toast("error", res.message);
                // Object.keys(res.errors).forEach((element) => {
                //   vm.toast(
                //     "error",
                //     typeof res.errors[element] == "object"
                //       ? res.errors[element][0]
                //       : res.errors[element]
                //   );
                // });
              } else {
                vm.$swal({
                  icon: "error",
                  text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            // console.log(error);
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.closeEdit();
        }

        // insert Data
        else {
          try {
            // this.datas.push(this.editedItem);
            let form = {
              name:
                typeof this.form.name !== "undefined"
                  ? this.form.name.toString().toUpperCase()
                  : "",
              title:
                typeof this.form.title !== "undefined" ? this.form.title : "",
              phone:
                typeof this.form.phone !== "undefined"
                  ? this.defaultPhoneCode +
                    this.form.phone.replace(/\s/g, "").replaceAll("-", "")
                  : "",
              email:
                typeof this.form.email !== "undefined"
                  ? this.form.email.toString().toUpperCase()
                  : "",
              primary: false,
            };

            // Begin api request
            (async function () {
              const res = await API.post(
                `${process.env.VUE_APP_API_TRUST}contact/insert`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("field.data_add_success"));
                setTimeout(function () {
                  vm.getDataContact();
                }, 200);
              } else if (res.statusCode == 403) {
                vm.toast("error", res.message);
                // Object.keys(res.errors).forEach((element) => {
                //   vm.toast(
                //     "error",
                //     typeof res.errors[element] == "object"
                //       ? res.errors[element][0]
                //       : res.errors[element]
                //   );
                // });
              } else {
                vm.$swal({
                  icon: "error",
                  text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            console.log(error);
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.close();
        }
      }
    },

    async getDataContact() {
      // console.log(`${Auth.getToken()}`);
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}contact/get`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          var new_data = [];

          res.results.map(function (e) {
            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "",
              name: typeof e.name !== "undefined" ? e.name : "",
              title: typeof e.title !== "undefined" ? e.title : "",
              phone: typeof e.phone !== "undefined" ? e.phone : "",
              email: typeof e.email !== "undefined" ? e.email : "",
              position: typeof e.position !== "undefined" ? e.position : "",
            });
          });

          this.datas = new_data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.uppercase-input input,
.v-input {
  text-transform: uppercase;
}
.table-contact-company thead {
      background: #ddd !important;
      font-weight: bold;
      color: #000 !important;
  }
  .table-contact-company thead tr th{
      height: 38px !important;
  }
  .table-contact-company tbody tr td{
      font-size: 12px !important;
      height: 35px !important;
  }
  .table-contact-company tbody tr:hover{
      background-color: transparent !important;
  }
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.v-input__control {
  width: 700px;
}

.v-toolbar__title {
  font-size: 16px;
}

.v-btn {
  display: flex;
  justify-content: flex-end;
}
.fontSet {
  font-size: 14px !important;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  height: 30px !important;
  min-width: 100px !important;
  justify-content: center;
}
</style>
