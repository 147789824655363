<template>
  <v-data-table :headers="headers" :items="datas" mobile-breakpoint="0" class="header-stretch table-bank-company"   :items-per-page="25"
    :hide-default-footer="true">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("page.profile.bank_info") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2 button"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("add") }}
            </v-btn>
          </template>
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{ $t("page.profile.add_new_bank") }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>

                <v-select
                 ref="bank_name"
                  v-model="bp_id"
                  :rules="[v => !!v || `${$t('page.profile.bank_name')} ${$t('is_required')}`]"
                  :items="listBank"
                  item-text="name"
                  item-value="bp_id"
                  class="fontSet"
                   :label="$t('page.profile.bank_name')"
                  dense
                ></v-select>
                <v-text-field
                  ref="account_no"
                  v-model="account_no"
                  :rules="[() => !!account_no || $t('is_required')]"
                  :label="$t('page.profile.bank_account_no')"
                  :placeholder="$t('page.profile.bank_account_no')"
                  class="fontSet"
                ></v-text-field>
                <v-text-field
                  ref="account_name"
                  v-model="account_name"
                  :rules="[() => !!account_name || $t('is_required')]"
                  :label="$t('page.profile.bank_account_name')"
                  :placeholder="$t('page.profile.bank_account_name')"
                  class="fontSet uppercase-input"
                ></v-text-field>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="close"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog v-model="dialogEdit" max-width="500px">
          <v-form ref="form" lazy-validation>
            <v-card>
              <v-card-title>{{ $t("page.profile.edit_bank") }}</v-card-title>
              <v-divider class="mt-1"></v-divider>
              <v-card-text>
               
                <v-select
                  dense
                  :items="listBank"
                  v-model="editedItem.bp_id"
                  item-text="name"
                  item-value="bp_id"
                   :label="$t('page.profile.bank_name')"
                  :rules="[() => !!editedItem.bp_id || $t('is_required')]"
                    class="fontSet"
                ></v-select>
               
                <v-text-field
                  ref="account_no"
                  v-model="editedItem.account_no"
                  :rules="[() => !!editedItem.account_no || $t('is_required')]"
                  :label="$t('page.profile.bank_account_no')"
                  :placeholder="$t('page.profile.bank_account_no')"
                  class="fontSet"
                ></v-text-field>
                <v-text-field
                  ref="account_name"
                  v-model="editedItem.account_name"
                  :rules="[
                    () => !!editedItem.account_name || $t('is_required'),
                  ]"
                  :label="$t('page.profile.bank_account_name')"
                  :placeholder="$t('page.profile.bank_account_name')"
                  class="fontSet uppercase-input"
                ></v-text-field>
                
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
              <v-card-actions class="justify-end">
                <v-btn text @click="closeEdit"> {{ $t("cancel") }} </v-btn>
                <v-btn color="#00a4b3" text @click="save">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("confirmation_dialog") }}
            </v-card-title>
            <v-card-text>
              {{ $t("page.profile.u_sure_want_delete") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete">{{ $t("cancel") }}</v-btn>
              <v-btn color="#00a4b3" text @click="deleteItemConfirm">{{
                $t("oke")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPrimary" max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{ $t("confirmation_dialog") }}
            </v-card-title>
            <v-card-text>
              {{ $t("page.profile.u_sure_make_primary") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closePrimary">{{ $t("cancel") }}</v-btn>
              <v-btn color="#00a4b3" text @click="makePrimaryConfirm">{{
                $t("oke")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item="{ item }">
      <tr>
        <td valign="top" class="pt-2">
          <v-icon small @click="editItem(item)" style="color:#00a4b3;"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)" style="color:#00a4b3;"> mdi-delete </v-icon>
          <v-icon
            small
            @click="makePrimary(item)"
            v-if="item.status != 'PRIMARY'"
            style="color:#00a4b3;"
          >
            mdi-heart
          </v-icon>
        </td>
        <td valign="top" class="pt-2 tb-wide">{{ item.name }}</td>
        <td valign="top" class="pt-2 tb-wide">{{ item.branch }}</td>
        <td valign="top" class="pt-2 tb-wide">{{ item.account_name }}</td>
        <td valign="top" class="pt-2 tb-wide2">{{ item.account_no }}</td>
        <td valign="top" class="pt-2">{{ item.status }}</td>
        
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogEdit: false,
    dialogPrimary: false,
    formHasError: false,
    headers: [],
    listBank:[],
    datas: [
      // {
      //   name: "BCA",
      //   branch: "ACEH SELATAN",
      //   account_no: "8376153933",
      //   account_name: "PT MAJU BERSAMA",
      //   status: "PRIMARY",
      // },
      // {
      //   name: "MANDIRI",
      //   branch: "ACEH SELATAN",
      //   account_no: "8726552431",
      //   account_name: "PT MAJU BERSAMA",
      //   status: "",
      // },
    ],

    editedIndex: -1,
    bp_id: null,
    branch: null,
    account_no: null,
    account_name: null,
    status: null,
    errorMessages: "",
    editedItem: {
      bp_id: "",
      branch: "",
      account_no: "",
      account_name: "",
      status: "",
    },
    defaultItem: {
      bp_id: "",
      branch: "",
      account_no: "",
      account_name: "",
      status: "",
    },
  }),
  created() {
    this.headers = [
      {
        text: this.$t("page.profile.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.bank_name"),
        sortable: false,
        align: "center",
        value: "name",
      },
      {
        text: this.$t("page.profile.bank_branch"),
        value: "branch",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.bank_account_no"),
        value: "account_no",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.bank_account_name"),
        value: "account_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
      
    ];
    this.getDataBank();
    this.getListBank();
  },
  computed: {
    form() {
      return {
        bp_id: this.bp_id,
        branch: this.branch,
        account_no: this.account_no,
        account_name: this.account_name,
        status: this.status,
      };
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    dialogPrimary(val) {
      val || this.closePrimary();
    },
    name() {
      this.errorMessages = "";
    },
  },
    // async mounted() {
    //   await API.get(`${process.env.VUE_APP_API_TRUST}bank-account/list-bank`, {
    //   "Content-Type": "application/json",
    //     Authorization: `Bearer ${Auth.getToken()}`,
    //   }).then(res => {
    //     if (res.statusCode == 200) {
    //       this.listBank = res.results;
         
    //     }
    //   });
    // },
  methods: {
    async getListBank(){
      await API.get(`${process.env.VUE_APP_API_TRUST}bank-account/list-bank`, {
      "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then(res => {
        if (res.statusCode == 200) {
          this.listBank = res.results;
         
        }
      });
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    makePrimary(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPrimary = true;
    },

    deleteItemConfirm() {
      let vm = this;
      try {
        let form = { id: this.datas[this.editedIndex].id };
        (async function () {
          const res = await API.del(
            `${process.env.VUE_APP_API_TRUST}bank-account/delete`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            [form]
          );

          if (res.statusCode === 200) {
            vm.toast("success", vm.$t("field.data_delete_success"));
            setTimeout(function () {
              vm.getDataBank();
            }, 200);
          } else if (res.statusCode == 403) {
            vm.toast("error", res.message);
            // Object.keys(res.errors).forEach(element => {
            //   vm.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            vm.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
        })();
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          text: vm.$t("error_try_again_latter"),
        });
      }
      this.closeDelete();
    },

    makePrimaryConfirm() {
      let vm = this;
      try {
        let form = { id: this.datas[this.editedIndex].id };
        (async function () {
          const res = await API.post(
            `${process.env.VUE_APP_API_TRUST}bank-account/set-primary`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            form
          );

          if (res.statusCode === 200) {
            vm.toast("success", vm.$t("field.data_edit_success"));
            setTimeout(function () {
              vm.getDataBank();
            }, 200);
          } else if (res.statusCode == 403) {
            vm.toast("error", res.message);
            // Object.keys(res.errors).forEach(element => {
            //   vm.toast(
            //     "error",
            //     typeof res.errors[element] == "object"
            //       ? res.errors[element][0]
            //       : res.errors[element]
            //   );
            // });
          } else {
            vm.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
        })();
      } catch (error) {
        // console.log(error);
        this.$swal({
          icon: "error",
          text: vm.$t("error_try_again_latter"),
        });
      }
      this.closePrimary();
    },

    close() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePrimary() {
      this.dialogPrimary = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.form.validate() == true) {
        // reference instance ke variabel karena tidak akan terbaca pada proses async
        var vm = this;
      
        // Jika ada edit item, karena jika diedit index akan selalu lebih dari -1
        if (this.editedIndex > -1) {
          // Object.assign(this.datas[this.editedIndex], this.editedItem);
          try {
            let form = {
              id:
                typeof this.editedItem.id !== "undefined"
                  ? this.editedItem.id
                  : 0,
              bank_bp_id:
                typeof this.editedItem.bp_id !== "undefined"
                  ? this.editedItem.bp_id.toString().toUpperCase()
                  : "",
              on_behalf:
                typeof this.editedItem.account_name !== "undefined"
                  ? this.editedItem.account_name.toString().toUpperCase()
                  : "",
              account_number:
                typeof this.editedItem.account_no !== "undefined"
                  ? this.editedItem.account_no.toString().toUpperCase()
                  : "",
            };

            // Begin api request
            (async function () {
              const res = await API.put(
                `${process.env.VUE_APP_API_TRUST}bank-account/update`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("field.data_edit_success"));
                setTimeout(function () {
                  vm.getDataBank();
                }, 200);
              } else if (res.statusCode == 403) {
                vm.toast("error", res.message);
                // Object.keys(res.errors).forEach(element => {
                //   vm.toast(
                //     "error",
                //     typeof res.errors[element] == "object"
                //       ? res.errors[element][0]
                //       : res.errors[element]
                //   );
                // });
              } else {
                vm.$swal({
                  icon: "error",
                  text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            // console.log(error);
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.closeEdit();
        }

        // insert Data
        else {
          try {
            // this.datas.push(this.editedItem);
            let form = {
              bank_bp_id:
                typeof this.form.bp_id !== "undefined"
                  ? this.form.bp_id.toString().toUpperCase()
                  : "",
              on_behalf:
                typeof this.form.account_name !== "undefined"
                  ? this.form.account_name.toString().toUpperCase()
                  : "",
              account_number:
                typeof this.form.account_no !== "undefined"
                  ? this.form.account_no.toString().toUpperCase()
                  : "",
              primary: false,
            };

            // Begin api request
            (async function () {
              const res = await API.post(
                `${process.env.VUE_APP_API_TRUST}bank-account/add`,
                {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${Auth.getToken()}`,
                },
                [form]
              );

              if (res.statusCode === 200) {
                vm.toast("success", vm.$t("field.data_add_success"));
                setTimeout(function () {
                  vm.getDataBank();
                }, 200);
              } else if (res.statusCode == 403) {
                vm.toast("error", res.message);
                // Object.keys(res.errors).forEach(element => {
                //   vm.toast(
                //     "error",
                //     typeof res.errors[element] == "object"
                //       ? res.errors[element][0]
                //       : res.errors[element]
                //   );
                // });
              } else {
                vm.$swal({
                  icon: "error",
                  text: vm.$t("error_try_again_latter"),
                });
              }
            })();
          } catch (error) {
            console.log(error);
            this.$swal({
              icon: "error",
              text: vm.$t("error_try_again_latter"),
            });
          }
          this.close();
        }
      }
    },

    async getDataBank() {
      // console.log(`${Auth.getToken()}`);
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}bank-account/get`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          var new_data = [];
          res.results.map(function (e) {
            var status =
              typeof e.primary !== "undefined" && e.primary ? "PRIMARY" : "-";
            new_data.push({
              bp_id: typeof e.bank_bp_id !== "undefined" ? e.bank_bp_id : "",
              id: typeof e.id !== "undefined" ? e.id : "",
              name: typeof e.bank_name !== "undefined" ? e.bank_name : "",
              branch: typeof e.branch !== "undefined" ? e.branch : "",
              account_no:
                typeof e.account_number !== "undefined"
                  ? e.account_number
                  : "",
              account_name:
                typeof e.on_behalf !== "undefined"
                  ? e.on_behalf
                  : "",
              status: status,
            });
          });
          this.datas = new_data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.uppercase-input input,
.v-input {
  text-transform: uppercase;
}
.table-bank-company thead {
      background: #ddd !important;
      font-weight: bold;
      color: #000 !important;
  }
  .table-bank-company thead tr th{
      height: 38px !important;
  }
  .table-bank-company tbody tr td{
      font-size: 12px !important;
      height: 35px !important;
  }
  .table-bank-company tbody tr:hover{
      background-color: transparent !important;
  }
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.v-input__control {
  width: 700px;
}

.v-toolbar__title {
  font-size: 16px;
}

.v-btn {
  display: flex;
  justify-content: flex-end;
}
.button {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  height: 30px !important;
  min-width: 100px !important;
  justify-content: center;
}
.fontSet {
  font-size: 14px !important;
}
</style>
